

export default [

    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Date',
        key: 'Date',
        // sortable: true,
        minWidth: 80,
    },

	{
        title: 'Narration',
        key: 'Narration',
		// sortable: true,
        minWidth: 80,
    },

    

    {
        title: 'Amount (Rs)',
        key: 'Amount',
        minWidth: 80,
    },
 
]
