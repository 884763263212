

export default [

    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Date',
        key: 'Date',
        // sortable: true,
        minWidth: 80,
    },

	{
        title: 'Narration	',
        key: 'Narration',
		// sortable: true,
        minWidth: 80,
    },

    
    {
        title: 'Mode of Travel',
        key: 'MOT',
        minWidth: 80,
        // render: (h, params) => {
        //     return h('span', params.row.MOT);
        // },
    },
    {
        title: 'Distance',
        key: 'Distance',
        minWidth: 80,

    },
    {
        title: 'Rate (RS)',
        key: 'Rate',
        minWidth: 80,
    },
    {
        title: 'Amount (Rs)',
        key: 'Amount',
        minWidth: 80,
    },
 
]
