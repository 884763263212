<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="Expense Claim"
      slot="title"
      link="/helpContent/ExpenseDetails"
    />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3">
          <inputText label="Name" v-model="form.name" :readonly="readOnly" />
        </div>
        <div class="col-sm-3">
          <inputText
            label="Staff Serial No. "
            v-model="form.serialNum"
            :readonly="readOnly"
          />
        </div>
        <div class="col-sm-3">
          <inputText
            label="Location "
            v-model="form.location"
            :readonly="readOnly"
          />
        </div>
        <div class="col-sm-3">
          <inputText
            label="Company Name "
            v-model="form.companyName"
            :readonly="readOnly"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <inputDate
            label="Date of claim"
            v-model="form.date"
            :readonly="readOnly"
          />
        </div>
        <div class="col-sm-4">
          <FormSelect
            label="Claim Category"
            :items="claimCategoryList"
            item-name="NAME"
            item-value="id"
            v-model="form.categoryId"
            :onChange="getComponent"
          />
        </div>
        <div class="col-sm-4">
          <inputText
            label="For the month "
            v-model="form.month"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <InputAmount
            label="Claim Amount (Rs) "
            v-model="form.amount"
            :readonly="readOnly"
          />
        </div>
        <div class="col-sm-4">
          <inputText
            label="Claim Amount (in words) "
            v-model="form.amountInWords"
            :readonly="readOnly"
          />
        </div>
        <div class="col-sm-4">
          <InputIntegerNumber
            label="No. of Attachment "
            v-model="form.attachments"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <inputTextArea label="Notes" v-model="form.notes" rules="required" />
        </div>
      </FormRow>
    </ValidationObserver>

    <!-- claim Form -->
    <ValidationObserver ref="validatorForm">
      <div v-if="claimForm">
        <h3>Claim Form</h3>
        <hr />
        <br />
        <FormRow>
          <div class="col-sm-3" v-if="voucherCon">
            Voucher
            <InputYesNo
              :Yes="1"
              :No="0"
              name="Voucher"
              v-model="form.voucher"
              rules="required"
            />
          </div>
          <div class="col-sm-2">
            <inputDate
              label="Claim Date"
              v-model="form.claimDate"
              rules="required"
              :options="options"
            />
          </div>
          <div class="col-sm-3">
            <inputText
              label="Narration"
              v-model="form.narration"
              rules="required"
            />
          </div>
          <div class="col-sm-2" v-if="travelModeCon">
            <FormSelect
              label="Mode of Travelling"
              :items="travelMode"
              item-name="Name"
              item-value="id"
              v-model="form.modeId"
              rules="required"
            />
          </div>
          <ButtonGroup>
            <FormButton type="success" @click="addMore()">Add</FormButton>
          </ButtonGroup>
        </FormRow>
        <FormRow>
          <div class="col-sm-1" v-if="distanceCon">
            <InputIntegerNumber
              label="Distance"
              v-model="form.distance"
              rules="required"
            />
          </div>
          <div class="col-sm-1" v-if="rateCon">
            <InputIntegerNumber label="Rate" v-model="form.rate" />
          </div>
          <div class="col-sm-2">
            <inputAmount label="Amount" v-model="form.claimAmount" />
          </div>

          <div class="col-sm-3">
            <inputTextArea
              label="Notes"
              v-model="form.claimNotes"
              rules="required"
            />
          </div>
        </FormRow>
      </div>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="tableConveyanceData"
      :columns="tableConveyance"
      :is-loading="isLoading"
      v-if="conveyance"
    >
      <template slot="actionsLeft">
          <TableButton @click="() => remove()">
              <img :src="require(`Assets/images/del.png`)"/>
              Delete
          </TableButton>
      </template>
    </DataTable>
    <DataTable
      :actions="true"
      :data="tableHolidayClaimData"
      :columns="tableHolidayClaim"
      :is-loading="isLoading"
      v-if="holidayClaim"
    >
    <template slot="actionsLeft">
          <TableButton @click="() => remove()">
              <img :src="require(`Assets/images/del.png`)"/>
              Delete
          </TableButton>
      </template>
    </DataTable>
    <DataTable
      :actions="true"
      :data="tableLateWorkingClaimData"
      :columns="tableLateWorkingClaim"
      :is-loading="isLoading"
      v-if="lateWorkingClaim"
    >
          <template slot="actionsLeft">
          <TableButton @click="() => remove()">
              <img :src="require(`Assets/images/del.png`)"/>
              Delete
          </TableButton>
      </template>
    </DataTable>
    <DataTable
      :actions="true"
      :data="tablePettyCashData"
      :columns="tablePettyCash"
      :is-loading="isLoading"
      v-if="pettyCash"
    >
          <template slot="actionsLeft">
          <TableButton @click="() => remove()">
              <img :src="require(`Assets/images/del.png`)"/>
              Delete
          </TableButton>
      </template>
    </DataTable>
    <br />

    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData(1)">Draft</FormButton>
          <FormButton type="success" @click="sendData(0)">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {
  getClaimCategory,
  travelMode,
  travelRate,
  getUserDetailsForExpense,
  addUpdateExpense,
  getClaimById,
  isSalaryfreeze,
  reimbursementAppliedTwiceForMonth,
  validationforholidayWorkingClaim,
  claimAlreadyApplied,
} from "../api";
import InputDate from "Components/form/InputDate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
import tableConveyance from "./conveyance";
import tableHolidayClaim from "./holidayClaim";
import tableLateWorkingClaim from "./lateWorkingClaim";
import tablePettyCash from "./pettyCash";
import DataTable from "Components/DataTable";
import moment from "moment";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    ValidationObserver,
    DataTable,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      form: {
        name: "",
        companyName: "",
        serialNum: "",
        location: "",
        categoryId: null,
        month: "",
        amount: "",
        amountInWords: "",
        attachments: "",
        notes: "",
        date: "",
        voucher: "",
        claimDate: "",
        narration: "",
        modeId: "",
        distance: "",
        rate: "",
        claimAmount: "",
        claimNotes: "",
        id: 0,
        isDraft: 0,
      },
      travelMode: [],
      claimCategoryList: [],
      claimForm: false,
      voucherCon: true,
      distanceCon: true,
      rateCon: true,
      travelModeCon: true,
      conveyanceData: [],
      holidayClaimData: [],
      lateWorkingClaimData: [],
      pettyCashData: [],
      tableData: [],
      tableConveyance,
      tableHolidayClaim,
      tableLateWorkingClaim,
      tablePettyCash,
      conveyance: false,
      holidayClaim: false,
      lateWorkingClaim: false,
      pettyCash: false,
      travelRateData: [],
      readOnly: true,
      // tables array
      tableConveyanceData: [],
      tableHolidayClaimData: [],
      tableLateWorkingClaimData: [],
      tablePettyCashData: [],
      options: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {
    "form.claimDate": function() {
      // getRate();
      console.log(this.form.categoryId);
      const data = {
        date: this.form.claimDate,
      };
      travelRate(data).then((res) => {
        this.travelRateData = res.data[0];
      });
    },
    "form.modeId": function(id) {
      if (id == 151) {
        this.form.rate = this.travelRateData.CarRate;
      } else {
        this.form.rate = this.travelRateData.AutoRate;
      }
      if (this.form.distance) {
        this.form.claimAmount = this.form.distance * this.form.rate;
      }
    },
    "form.distance": function(distance) {
      this.form.claimAmount = distance * this.form.rate;
    },
  },
  created() {
    this.getUserDetails();
  },
  methods: {
    async getUserDetails() {
      await getUserDetailsForExpense().then((res) => {
        let data = res.data[0].UserDetails;
        res = JSON.parse(data).userDetails[0];
        this.form.name = this.userData.user_name;
        this.form.serialNum = res.empcode;
        this.form.location = res.LocationName;
        this.form.date = res.probation_completion_date;
        this.form.companyName = res.Name;
      });
      this.getDropDowns();
      if (this.$route.params.id) {
        this.form.id = atob(this.$route.params.id);
        this.getClaimData();
      }
    },
    getClaimData() {
      const data = {
        id: this.form.id,
      };
      getClaimById(data).then((res) => {
        this.form.categoryId = res.data[0].ClaimCategory_id;
        this.form.isDraft = res.data[0].IsSaveAsDraft;
        if (res.data[0].ClaimCategory_id) {
          this.getComponent(res.data[0].ClaimCategory_id);
          this.showTable(res.data[0].ClaimCategory_id, res.tableData);
        }
        this.form.month = res.data[0].ForTheMonth;
        this.form.attachments = res.data[0].AttachmentCount;
        this.form.notes = res.data[0].Notes;
      });
    },

    addMore() {
      this.form.claimAmount = this.form.distance * this.form.rate;
      let cuurentmonth = moment().month();
        let currentday = moment().date();
        let currentyear = moment().year();
        let day = moment(this.form.claimDate).date();
        let month = moment(this.form.claimDate).month();
        let year = moment(this.form.claimDate).year();
      this.$refs.validatorForm.validate().then((result) => {
        if (result) {
          if (this.form.categoryId == 144) {
            //Conveyance
            if (day <= 15 && month != cuurentmonth)
            {

               console.log("1.Applied date exceeding expense claim limit")
                return;
            }
            else if (day > 15 && cuurentmonth == month + 1 && currentday > 15)
            {
               console.log("2.Applied date exceeding expense claim limit")
                return;
            }
            else{
              const data = {
              date: this.form.claimDate,
              staffId: this.userData.user_id,
              claimId: this.form.categoryId,
            };
            claimAlreadyApplied(data).then((res) => {
              console.log("claimAlreadyApplied=", res.data[0].Result);
              if(res.data[0].Result==1){
                this.tableConveyanceData.push({
              Date: this.form.claimDate,
              Narration: this.form.narration,
              MOT: this.form.modeId,
              Distance: this.form.distance,
              Rate: this.form.rate,
              Amount: this.form.claimAmount,
            });
            console.log("tableConveyanceData=", this.tableConveyanceData);
            this.tableData = this.tableConveyanceData;
            this.conveyance = true;
              }else{

              }
    
            });
            }
          } 
        //   else if(this.form.categoryId == 145){ // In Oct, Staff can claim Medical Reimbursement for April to Sept  and In April , for Oct to March
        //     const data = {
        //   year: moment().year(),
        //   month: moment().month(),
        //   claimId: e,
        //   staffId: this.userData.user_id,
        // };
        // reimbursementAppliedTwiceForMonth(data).then((res) => {
        //   console.log("reimbursementAppliedTwiceForMonth=", res);
        // });
        //   }
          else if (this.form.categoryId == 147) { // petty cash can only claim with in month
            //Petty Cash
            this.tablePettyCashData.push({
              Date: this.form.claimDate,
              Narration: this.form.narration,
              Amount: this.form.claimAmount,
              MOT: this.form.modeId,
              Distance: this.form.distance,
              Rate: this.form.rate,
            });
            console.log("tablePettyCashData=", this.tablePettyCashData);
            this.conveyance = false;
            this.holidayClaim = true;
            this.tableData = this.tablePettyCashData;
          } else if (this.form.categoryId == 153) {
            //Holiday Claim
            this.tableHolidayClaimData.push({
              Date: this.form.claimDate,
              Narration: this.form.narration,
              Amount: this.form.claimAmount,
              voucher: this.form.voucher,
              MOT: this.form.modeId,
              Distance: this.form.distance,
              Rate: this.form.rate,
            });
            this.holidayClaim = true;
            console.log("tableHolidayClaimData=", this.tableHolidayClaimData);
            // this.lateWorkingClaim=true;
            this.conveyance = false;
            this.tableData = this.tableHolidayClaimData;
          } else if (this.form.categoryId == 268) {
            //Late working claim
            const data = {
              date: this.form.claimDate,
              staffId: this.userData.user_id,
            };
            validationforholidayWorkingClaim(data).then((res) => {
              console.log("validationforholidayWorkingClaim=", res);
              if (res.data[0].result===1) {
                     // claimAlreadyApplied
                     console.log("1=");
                     const data = {
                  date: this.form.claimDate,
                  staffId: this.userData.user_id,
                  claimId: this.form.categoryId,
                };
                claimAlreadyApplied(data).then((res) => {
                  console.log("claimAlreadyApplied=");
                });
              } 
              else if(res.data[0].result===2){
                console.log("2=");
              }
              else if(res.data[0].result===3){
                console.log("3=");
              }
              else {
                console.log("4=");
              }
            });
            this.tableLateWorkingClaimData.push({
              Date: this.form.claimDate,
              Narration: this.form.narration,
              Amount: this.form.claimAmount,
              voucher: this.form.voucher,
              MOT: this.form.modeId,
              Distance: this.form.distance,
              Rate: this.form.rate,
            });
            console.log(
              "tableLateWorkingClaimData=",
              this.tableLateWorkingClaimData
            );
            this.conveyance = false;
            this.pettyCash = true;
            this.tableData = this.tableLateWorkingClaimData;
          }
             // amount in word
             let sum=0
      this.tableData.forEach((item) => {
        sum=sum+item.Amount
      });
      console.log("sun=",sum)
      this.numberIntoWords(parseInt(sum));
        }
      });
   
    },
    async getDropDowns() {
      await getClaimCategory().then((res) => {
        this.claimCategoryList = res.data;
      });
      await travelMode().then((res) => {
        this.travelMode = res.data;
      });
    },
    showTable(e, data) {
      console.log("e=", e, "data=", data);
      if (e == 4) {
        //Petty Cash
        this.pettyCash = true;
      } else if (e == 144) {
        //Conveyance
        data.forEach((item) => {
          this.tableConveyanceData.push({
            Date: item.ExpenseDate,
            Narration: item.Narration,
            MOT: item.TravelMode_Id,
            Distance: item.Distance,
            Rate: item.Rate,
            Amount: item.Amount,
          });
        });
        this.conveyance = true;
        this.tableData = this.tableConveyanceData;
      } else if (e == 147) {
        // petty Cash
        data.forEach((item) => {
          this.tablePettyCashData.push({
            Date: item.ExpenseDate,
            Narration: item.Narration,
            MOT: item.TravelMode_Id,
            Distance: item.Distance,
            Rate: item.Rate,
            Amount: item.Amount,
          });
        });
        this.pettyCash = true;
        this.tableData = this.tablePettyCashData;
      } else if (e == 153) {
        //Holiday Claim
        data.forEach((item) => {
          this.tableHolidayClaimData.push({
            Date: item.claimDate,
            Narration: item.narration,
            Amount: item.claimAmount,
            voucher: item.voucher,
            MOT: item.modeId,
            Distance: item.distance,
            Rate: item.rate,
          });
        });
        this.holidayClain = true;
        this.tableData = this.tableHolidayClaimData;
      } else if (e == 268) {
        //Late working claim
        data.forEach((item) => {
          this.tableLateWorkingClaimData.push({
            Date: item.claimDate,
            Narration: item.narration,
            Amount: item.claimAmount,
            voucher: item.voucher,
            MOT: item.modeId,
            Distance: item.distance,
            Rate: item.rate,
          });
        });
        this.lateWorkingClaim = true;
        this.tableData = this.tableLateWorkingClaimData;
      }
    },

    getComponent(e) {
      if (e == 4) {
        //Petty Cash
        this.form.claimAmount = "";
        this.travelModeCon = false;
        this.distanceCon = false;
        this.rateCon = false;
        this.claimForm = true;
        this.claimForm = true;
        this.voucherCon = false;
        this.conveyance = false;
      } else if (e == 144) {
        //Conveyance
        this.voucherCon = false;
        this.travelModeCon = true;
        this.distanceCon = true;
        this.rateCon = true;
        this.form.claimAmount = "";
        this.claimForm = true;
      } else if (e == 147) {
        this.form.claimAmount = "";
        this.voucherCon = false;
        this.travelModeCon = false;
        this.distanceCon = false;
        this.rateCon = false;
        this.claimForm = true;
        this.conveyance = false;
      } else if (e == 153) {
        //Holiday Claim
        this.form.claimAmount = 150;
        this.travelModeCon = false;
        this.distanceCon = false;
        this.rateCon = false;
        this.claimForm = true;
        this.voucherCon = true;
        this.conveyance = false;
      } else if (e == 268) {
        //Late working claim
        this.form.claimAmount = 150;
        this.travelModeCon = false;
        this.distanceCon = false;
        this.rateCon = false;
        this.claimForm = true;
        this.voucherCon = true;
        this.conveyance = false;
      } else {
        this.form.claimAmount = "";
        this.claimForm = false;
      }
      // mobile reimbursement
      if (e === 4) {
        const data = {
          year: moment().year(),
          month: moment().month(),
          claimId: e,
          staffId: this.userData.user_id,
        };
        isSalaryfreeze(data).then((res) => {
          console.log("isSalaryfreeze=", res);
        });
      }
      // medical reimbursement
      //       if(e==145){
      //         // reimbursementAppliedTwiceForMonth
      //         const data={
      // year:moment().year(),
      // month:moment().month(),
      // claimId:e,
      // staffId:this.userData.user_id,
      // };
      // reimbursementAppliedTwiceForMonth(data).then(res=>{
      // console.log("reimbursementAppliedTwiceForMonth=",res)
      // });
      //       }
      else if (e == 4 || e == 10 || e == 11 || e == 145) {
        // reimbursementAppliedTwiceForMonth
        const data = {
          year: moment().year(),
          month: moment().month(),
          claimId: e,
          staffId: this.userData.user_id,
        };
        reimbursementAppliedTwiceForMonth(data).then((res) => {
          console.log("reimbursementAppliedTwiceForMonth=", res);
        });
      }
    },
    sendData(via) {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          const data = {
            name: this.form.name,
            serialNum: this.form.serialNum,
            location: this.form.location,
            claimcategory: this.form.categoryId,
            forMonth: this.form.month,
            amount: this.form.amount,
            amountInWords: this.form.amountInWords,
            attachcount: this.form.attachments,
            notes: this.form.notes,
            date: this.form.date,
            expensejson: JSON.stringify(this.tableData),
            isDraft: via == 1 ? 1 : 0, //for draft 1 means draft ,
            id: this.form.id,
          };
          addUpdateExpense(data).then((res) => {
            this.form = {};
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          });
        }
      });
    },
    remove() {
        if (this.validateSelectedLength(1)) {
          if (this.deleteSelectedValue()) {
          }
        }
      },
      confirmDelete() {
        this.showWarningModal(false);
        const data = {
          id: this.selectedRows[0].id,
        };
        removeLeave(data).then((res) => {
          if (res.data[0].status > 1) {
            this.showWarningModal(true);
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.warningModal.message = res.data[0].message;
            this.selectedRows = [];
          } else {
            this.selectedRows = [];
            this.warningModal.buttons.ok = true;
            this.warningModal.buttons.cancel = false;
            this.warningModal.buttons.confirm = false;
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          }
        });
        this.getDataByFilter();
      },
    numberIntoWords(n) {
      if (n < 0) return false;
      let single_digit = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      let double_digit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      let below_hundred = [
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      if (n === 0) return "Zero";
      function translate(n) {
        let word = "";
        if (n < 10) {
          word = single_digit[n] + " ";
        } else if (n < 20) {
          word = double_digit[n - 10] + " ";
        } else if (n < 100) {
          let rem = translate(n % 10);
          word = below_hundred[(n - (n % 10)) / 10 - 2] + " " + rem;
        } else if (n < 1000) {
          word =
            single_digit[Math.trunc(n / 100)] +
            " Hundred " +
            translate(n % 100);
        } else if (n < 1000000) {
          word =
            translate(parseInt(n / 1000)).trim() +
            " Thousand " +
            translate(n % 1000);
        } else if (n < 1000000000) {
          word =
            translate(parseInt(n / 1000000)).trim() +
            " Million " +
            translate(n % 1000000);
        } else {
          word =
            translate(parseInt(n / 1000000000)).trim() +
            " Billion " +
            translate(n % 1000000000);
        }
        return word;
      }
      let result = translate(n);
      // return result.trim()+'.'
      this.form.amountInWords = result.trim() + ".";
      this.form.amount = n;
    },
  },
};
</script>
